import React from 'react';
import styles from './Header.module.scss';
import LogoMobile from '../../assets/logo-mobile.jpg';
import Logo from '../../assets/logo.jpg';
import { Link } from 'react-router-dom';
import { FiPhoneCall } from 'react-icons/fi';
import {
  FaWhatsapp,
  FaYoutube,
  FaFacebook,
  FaLinkedin,
  FaInstagram
} from 'react-icons/fa';
import { MdOutlineEmail } from 'react-icons/md';
import { useMedia } from 'src/hooks/useMedia';
import { IoMdArrowDropdown } from 'react-icons/io';
import HeaderUserbox from 'src/layouts/SidebarLayout/Header/Userbox';
import { Button } from '@mui/material';
import Subheader from './Subheader/Subheader';
import {
  getEmail,
  getFacebook,
  getInstagram,
  getLinkedin,
  getPhone,
  getWhatsapp,
  getYoutube
} from '../../api/api';

const Header = () => {
  const [whatsappLink, setWhatsappLink] = React.useState('');
  const [emailLink, setEmailLink] = React.useState('');
  const [phoneLink, setPhoneLink] = React.useState('');
  const [facebookLink, setFacebookLink] = React.useState('');
  const [linkedinLink, setLinkedinLink] = React.useState('');
  const [instagramLink, setInstagramLink] = React.useState('');
  const [youtubeLink, setYoutubeLink] = React.useState('');

  React.useEffect(() => {
    getWhatsapp(setWhatsappLink);
    getEmail(setEmailLink);
    getPhone(setPhoneLink);
    getFacebook(setFacebookLink);
    getLinkedin(setLinkedinLink);
    getInstagram(setInstagramLink);
    getYoutube(setYoutubeLink);
  }, []);

  React.useEffect(() => {
    console.log({
      whatsappLink,
      emailLink,
      linkedinLink,
      instagramLink,
      youtubeLink
    });
  }, []);
  const [mobileMenu, setMobileMenu] = React.useState(false);
  const mobile = useMedia('(max-width: 40rem)');
  const login =
    window.localStorage.getItem('token') &&
    window.localStorage.getItem('token').length > 0;

  const [openDropDown, setOpenDropDown] = React.useState(false);

  return (
    <>
      {mobile && (
        <>
          <Subheader />
          <section className={styles.headerMobile}>
            <img
              src={LogoMobile}
              style={{ width: '120px', height: '50px', objectFit: 'contain' }}
            />
            <div className={styles.headerMobile__nav}>
              <ul className={styles.headerMobile__nav__ul}>
                <li>
                  <a href={phoneLink}>
                    <FiPhoneCall size={20} />
                  </a>
                </li>
                <li>
                  <a href={whatsappLink}>
                    <FaWhatsapp size={20} />
                  </a>
                </li>
                <li>
                  <a href={emailLink}>
                    <MdOutlineEmail size={20} />
                  </a>
                </li>
              </ul>
            </div>
            <div className={styles.headerMobile__divButton}>
              <button
                aria-label="Menu"
                className={`${styles.mobileButton} ${
                  mobileMenu && styles.mobileButtonActive
                }`}
                onClick={() => setMobileMenu(!mobileMenu)}
              ></button>
            </div>
          </section>
          <section
            className={`${mobile ? styles.navMobile : styles.nav} ${
              mobileMenu && styles.navMobileActive
            }`}
          >
            {login ? (
              <div style={{ marginTop: '1rem', marginLeft: '-.5rem' }}>
                <HeaderUserbox />
              </div>
            ) : (
              <div>
                <Link to="/login">
                  <button
                    style={{
                      background: 'rgb(18, 80, 130)',
                      padding: '.8rem',
                      border: 'none',
                      cursor: 'pointer',
                      color: '#ddd',
                      borderRadius: '8px',
                      width: '120px'
                    }}
                  >
                    Login
                  </button>
                </Link>
              </div>
            )}
            <ul className={styles.navMobile__menu}>
              <Link to="/">Home</Link>
              <Link to="/como-funciona">Como Funciona</Link>
              <Link to="/alugue-online">Alugue Online</Link>
              <Link to="/demonstracao">Demonstração</Link>
              <a href="https://nestrental.mobhis.com.br">Nest Express</a>
              <Link to="/contato">Contato</Link>
              <a href="https://www.plataformaelevatoria.eco.br">Blog</a>
            </ul>
          </section>
        </>
      )}

      {!mobile && (
        <section className={styles.header}>
          <Subheader />
          <div className={styles.header__sub}>
            <div>
              <ul className={styles.header__sub__infos}>
                <a href={phoneLink}>
                  <FiPhoneCall size={23} />
                  0800 555 1015
                </a>
                <a href="tel:11941707718">
                  <FiPhoneCall size={23} />
                  Nest Express (11) 94170-7718
                </a>
                <a href={whatsappLink}>
                  <FaWhatsapp size={23} />
                  (11) 9 8844-6299
                </a>
                <a href={emailLink}>
                  <MdOutlineEmail size={23} />
                  contato@nestrental.com.br
                </a>
              </ul>
            </div>
            <div>
              <ul className={styles.header__sub__socialMedias}>
                <a href={facebookLink}>
                  <FaFacebook size={23} />
                </a>
                <a href={linkedinLink}>
                  <FaLinkedin size={23} />
                </a>
                <a href={instagramLink}>
                  <FaInstagram size={23} />
                </a>
                <a href={youtubeLink}>
                  <FaYoutube size={23} />
                </a>
              </ul>
            </div>
          </div>
          <header className={styles.header__main}>
            <div className={styles.header__main__logo}>
              <Link to="/">
                <img src={Logo} alt="Logo - Nest Rental" />
              </Link>
            </div>

            <ul className={styles.header__links}>
              <Link to="/como-funciona">Como funciona</Link>
              <a href="/alugue-online">Alugue Online</a>
              <a
                href="#"
                className={styles.header__links__venda}
                onClick={(e) => setOpenDropDown(!openDropDown)}
              >
                Vendas <IoMdArrowDropdown />
              </a>
              <Link to="/demonstracao">Demonstração</Link>
              <a href="https://nestrental.mobhis.com.br/">Nest Express</a>
              <a href="https://www.plataformaelevatoria.eco.br/">Blog</a>
              <Link to="/contato">Contato</Link>
              <div
                className={`${
                  openDropDown
                    ? styles.header__links__dropdownActive
                    : styles.header__links__dropdownDeactive
                }
                                    ${styles.header__links__dropdown}`}
              >
                <a href="https://nestrental.com.br/venda-liftpod-ft140-plataforma-low-level/">
                  LiftPod FT140
                </a>
              </div>
            </ul>

            {login ? (
              <HeaderUserbox />
            ) : (
              <div>
                <Link to="/login">
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    size="large"
                    fullWidth={true}
                  >
                    Login
                  </Button>
                </Link>
              </div>
            )}
          </header>
        </section>
      )}
    </>
  );
};

export default Header;
